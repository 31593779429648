export default {
  faces_age: 'age',
  faces_beard: 'beard',
  faces_beard__beard: 'beard',
  faces_beard__none: 'no beard',
  faces_emotions: 'emotions',
  faces_emotions__angry: 'anger',
  faces_emotions__disgust: 'disgust',
  faces_emotions__fear: 'fear',
  faces_emotions__happy: 'happiness',
  faces_emotions__neutral: 'neutral expression',
  faces_emotions__sad: 'sadness',
  faces_emotions__surprise: 'surprise',
  faces_gender: 'gender',
  faces_gender__female: 'woman',
  faces_gender__male: 'man',
  faces_glasses: 'glasses',
  faces_glasses__eye: 'eyeglasses',
  faces_glasses__none: 'no glasses',
  faces_glasses__sun: 'sunglasses',
  faces_liveness: 'liveness',
  faces_liveness__fake: 'fake',
  faces_liveness__real: 'real',
  faces_look: 'look',
  faces_look__black: 'black',
  faces_look__white: 'white',
  faces_look__asian: 'asian',
  faces_look__indian: 'indian',
  faces_medmask: 'face mask',
  faces_medmask__correct: 'mask on',
  faces_medmask__incorrect: 'mask improperly worn',
  faces_medmask__none: 'no mask',
  faces_headpose_yaw: 'head turn',
  faces_headpose_pitch: 'head tilt',
  cars_body: 'vehicle body type',
  cars_body__cab: 'pickup',
  cars_body__convertible: 'convertible',
  cars_body__coupe: 'coupe',
  cars_body__crossover: 'crossover',
  cars_body__limousine: 'limousine',
  cars_body__minibus: 'van',
  cars_body__minivan: 'minivan',
  cars_body__sedan: 'sedan',
  cars_body__suv: 'suv',
  cars_body__wagon: 'shooting brake',
  cars_body__coming_soon: 'coming soon',
  cars_body__unknown: 'vehicle body type unknown',
  cars_color: 'vehicle body color',
  cars_color__unknown: 'vehicle body color unknown',
  cars_color__beige: 'beige',
  cars_color__black: 'black',
  cars_color__blue: 'blue',
  cars_color__brown: 'brown',
  cars_color__cyan: 'cyan',
  cars_color__gold: 'gold',
  cars_color__green: 'green',
  cars_color__grey: 'gray',
  cars_color__orange: 'orange',
  cars_color__pink: 'pink',
  cars_color__purple: 'purple',
  cars_color__red: 'red',
  cars_color__silver: 'silver',
  cars_color__violet: 'violet',
  cars_color__white: 'white',
  cars_color__yellow: 'yellow',
  cars_color__coming_soon: 'coming soon',
  cars_license_plate_country: 'country',
  cars_license_plate_number: 'license plate number',
  cars_license_plate_number__unknown: 'license plate number unknown',
  cars_license_plate_region: 'region',
  cars_license_plate_region__unknown: 'License plate region unknown',
  cars_license_plate_region__ADH: 'ADH (Abu Dhabi)',
  cars_license_plate_region__AJM: 'AJM (Ajman)',
  cars_license_plate_region__DXB: 'DXB (Dubai)',
  cars_license_plate_region__FUJ: 'FUJ (Fujairah)',
  cars_license_plate_region__RAK: 'RAK (Ras Al Khaimah)',
  cars_license_plate_region__SHJ: 'SHJ (Sharjah)',
  cars_license_plate_region__UAK: 'UAK (Umm Al Quwain)',
  cars_make: 'make',
  cars_make__unknown: 'car make unknown',
  cars_model: 'model',
  cars_model__unknown: 'car model unknown',
  cars_special_vehicle_type: 'special vehicle',
  cars_special_vehicle_type__unknown: 'vehicle special use unknown',
  cars_special_vehicle_type__police: 'Police (incl. traffic police)',
  cars_special_vehicle_type__ambulance: 'Ambulance (incl. mobile ICU)',
  cars_special_vehicle_type__rescue_service: 'Fire service and EMERCOM vehicles',
  cars_special_vehicle_type__taxi: 'Taxi (incl. cargo taxi)',
  cars_special_vehicle_type__not_special: 'Not special',
  cars_special_vehicle_type__military: 'Military (incl. National Guard)',
  cars_special_vehicle_type__road_service: 'Municipal vehicles (incl. Center for the Organization of Road Traffic)',
  cars_special_vehicle_type__other_special: 'Other special',
  cars_special_vehicle_type__route_transport: 'Public road transport',
  cars_special_vehicle_type__car_sharing: 'Carsharing',
  cars_special_vehicle_type__gas_service: 'Gas rescue and emergency services',
  cars_category: 'Vehicle category',
  cars_category__A: 'Motorcycle, scooter, ATV',
  cars_category__B: 'Car',
  cars_category__BE: 'Car with a trailer',
  cars_category__C: 'Truck',
  cars_category__CE: 'Truck with a trailer',
  cars_category__D: 'Bus',
  cars_category__DE: 'Articulated bus',
  cars_category__other: 'Other category vehicle',
  cars_category__unknown: 'Vehicle category unknown',
  cars_weight_type: 'Vehicle weight and body size',
  cars_weight_type__unknown: 'Vehicle weight and body size unknown',
  cars_weight_type__B_light: 'Cars under 3.5 tons',
  cars_weight_type__B_heavy: 'Trucks under 3.5 tons',
  cars_weight_type__C_light: 'Trucks under 3.5-12 tons',
  cars_weight_type__C_heavy: 'Trucks over 12 tons',
  cars_weight_type__D_light: 'Single-deck buses',
  cars_weight_type__D_long: 'Articulated buses',
  cars_weight_type__other: 'Other weight class vehicle',
  cars_orientation: 'Vehicle orientation',
  cars_orientation__unknown: 'Vehicle orientation unknown',
  cars_orientation__front: 'Front',
  cars_orientation__back: 'Rear',
  cars_orientation__side: 'Side',
  bodies_bottom_color: 'lower clothes color',
  bodies_bottom_color__beige: 'beige',
  bodies_bottom_color__black: 'black',
  bodies_bottom_color__blue: 'blue',
  bodies_bottom_color__brown: 'brown',
  bodies_bottom_color__green: 'green',
  bodies_bottom_color__grey: 'gray',
  bodies_bottom_color__lightblue: 'light blue',
  bodies_bottom_color__orange: 'orange',
  bodies_bottom_color__pink: 'pink',
  bodies_bottom_color__purple: 'purple',
  bodies_bottom_color__red: 'red',
  bodies_bottom_color__white: 'white',
  bodies_bottom_color__yellow: 'yellow',
  bodies_bottom_color__violet: 'violet',
  bodies_top_color: 'upper clothes color',
  bodies_top_color__beige: 'beige',
  bodies_top_color__black: 'black',
  bodies_top_color__blue: 'blue',
  bodies_top_color__brown: 'brown',
  bodies_top_color__green: 'green',
  bodies_top_color__grey: 'gray',
  bodies_top_color__lightblue: 'light blue',
  bodies_top_color__orange: 'orange',
  bodies_top_color__pink: 'pink',
  bodies_top_color__purple: 'purple',
  bodies_top_color__red: 'red',
  bodies_top_color__white: 'white',
  bodies_top_color__yellow: 'yellow',
  bodies_top_color__violet: 'violet',
  bodies_detailed_upper_clothes: 'upper clothes type',
  bodies_detailed_upper_clothes__jacket: 'jacket',
  bodies_detailed_upper_clothes__coat: 'coat',
  bodies_detailed_upper_clothes__sleeveless: 'sleeveless',
  bodies_detailed_upper_clothes__sweatshirt: 'sweatshirt',
  'bodies_detailed_upper_clothes__t-shirt': 't-shirt',
  bodies_detailed_upper_clothes__shirt: 'shirt',
  bodies_detailed_upper_clothes__dress: 'dress',
  bodies_headwear: 'headwear',
  bodies_headwear__hat: 'hat, cap',
  bodies_headwear__none: 'none',
  bodies_headwear__hood: 'hood, scarf',
  bodies_lower_clothes: 'lower body clothes',
  bodies_lower_clothes__pants: 'pants',
  bodies_lower_clothes__obscured: 'nondescript',
  bodies_lower_clothes__skirt: 'skirt',
  bodies_lower_clothes__shorts: 'shorts',
  bodies_upper_clothes: 'upper body clothes',
  bodies_upper_clothes__long_sleeves: 'long sleeves',
  bodies_upper_clothes__short_sleeves: 'short sleeves',
  bodies_upper_clothes__without_sleeves: 'no sleeves',
  bodies_vest_type: 'vest',
  'bodies_vest_type__green/yellow': 'green/yellow',
  bodies_vest_type__orange: 'orange',
  bodies_vest_type__none: 'none',
  bodies_vest_type__not_visible: 'not visible',
  bodies_vest_type_score: 'vest score',
  bodies_helmet_type: 'helmet',
  bodies_helmet_type__white: 'white',
  'bodies_helmet_type__red/orange': 'red/orange',
  bodies_helmet_type__other: 'other',
  bodies_helmet_type__none: 'none',
  bodies_helmet_type__not_visible: 'not visible',
  bodies_helmet_type_score: 'helmet score',
  bodies_bag_back: 'bag on the back',
  bodies_bag_back__back: 'yes',
  bodies_bag_back__none: 'none',
  bodies_bag_hand: 'bag in hand',
  bodies_bag_hand__hand: 'yes',
  bodies_bag_hand__none: 'none',
  bodies_bag_ground: 'bag on the floor',
  bodies_bag_ground__ground: 'yes',
  bodies_bag_ground__none: 'none',
  bodies_gender: 'gender by body',
  bodies_gender__male: 'man',
  bodies_gender__female: 'woman',
  bodies_age_group: 'age by body',
  'bodies_age_group__0-16': '0-16 years',
  'bodies_age_group__17-35': '17-35 years',
  'bodies_age_group__36-50': '36-50 years',
  'bodies_age_group__50+': '50+ years',
  cars_license_plate_country__unknown: 'License plate country unknown',
  cars_license_plate_country__RU: 'RUS (Russia)',
  cars_license_plate_country__AE: 'UAE (UAE)',
  cars_license_plate_country__KZ: 'KZ (Kazakhstan)',
  cars_license_plate_country__GE: 'GE (Georgia)',
  cars_license_plate_country__SA: 'KSA (Saudi Arabia)',
  cars_license_plate_country__VN: 'VN (Vietnam)',
  cars_license_plate_country__BY: 'BY (Belarus)',
  cars_license_plate_country__UA: 'UA (Ukraine)',
  cars_license_plate_country__AM: 'AM (Armenia)',
  cars_license_plate_country__KG: 'KG (Kyrgyzstan)',
  cars_license_plate_country__UZ: 'UZ (Uzbekistan)',
  cars_license_plate_country__BR: 'BR (Brazil)',
  cars_license_plate_country__IN: 'IND (India)',
  cars_license_plate_country__AZ: 'AZ (Azerbaijan)',
  cars_license_plate_country__EST: 'EST (Estonia)',
  cars_license_plate_country__FIN: 'FIN (Finland)',
  cars_license_plate_country__LT: 'LT (Lithuania)',
  cars_license_plate_country__LV: 'LV (Latvia)',
  cars_license_plate_country__MD: 'MD (Moldova)',
  cars_license_plate_country__MX: 'MEX (Mexico)',
  cars_license_plate_country__AR: 'RA (Argentina)',
  cars_license_plate_country__SRB: 'SRB (Serbia)',
  cars_license_plate_country__TJ: 'TJ (Tajikistan)',
  cars_license_plate_country__TM: 'TM (Turkmenistan)',
  cars_license_plate_country__Country_1: 'unknown',
  cars_license_plate_country__Country_2: 'unknown',
  cars_license_plate_country__Country_3: 'unknown',
  cars_license_plate_country__Country_4: 'unknown',
  cars_license_plate_country__Country_5: 'unknown',
  cars_license_plate_country__TH: 'T (Thailand)',
  cars_license_plate_country__CZ: 'CZ (Czech Republic)',
  cars_license_plate_country__PK: 'PK (Pakistan)',
  cars_license_plate_number_color: 'license plate color',
  cars_license_plate_number_color__white: 'License plate white',
  cars_license_plate_number_color__yellow: 'License plate yellow',
  cars_license_plate_number_color__blue: 'License plate blue',
  cars_license_plate_number_color__green: 'License plate green',
  cars_license_plate_number_color__grey: 'License plate grey',
  cars_license_plate_number_color__black: 'License plate black',
  cars_license_plate_number_color__unknown: 'License plate color unknown'
};
